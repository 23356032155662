import VueDropzone from 'vue2-dropzone'
import excelParser from '../../../../services/excel-parser'
import LocationModal from './location-modal'
import {COMMUNICATION_TYPE, PRODUCT_DISRUPTION_TYPES } from '../../../../misc/commons'
import supplierRecallService from '../../../../services/supplier/supplier-recall'
import _ from 'lodash'
export default {
    components: {
        VueDropzone,
        LocationModal
    },
    props: ['communicationId', 'recallDisabled', 'infoData','communicationType'],
    data() {
        return {
            dropzoneOptions: {
                url: '#',
                acceptedFiles: '.xlsx,.xls',
                maxFiles: 1,
                maxFilesize: 20,
                previewsContainer: false,
                autoProcessQueue: false
            },
            item: {},
            listHeader: [],
            isFileSelect: false,
            locationModalKey: 0,
            productDisruption: COMMUNICATION_TYPE.PRODUCTDISRUPTION,
            productDisruptionTypes: PRODUCT_DISRUPTION_TYPES,
            coveredListSearchQuery: {},
            locationsCoveredList: [],
            totalLocationsCovered: 0,
            locationsEmailedList: [],
            totalLocationsEmailed: 0,
            notCoveredListSearchQuery: {},
            locationsNotCoveredList: [],
            totalLocationsNotCovered: 0,
            isUploaded: false,
            unRecognisedListSearchQuery: {},
            locationsUnRecognisedList: [],
            totalLocationsUnRecognised: 0,
            info: {},
            selectAllCovered: false,
            selectAllEmailed: false,
            selectAllNonCovered: false,
            selectAllUnrecognized: false,
            isEnable: false,
            Listitems: [],
            coveredLocationIds: [],
            emailedLocationIds: [],
            notCoveredLocationIds: [],
            unRecognisedLocationIds: [],
            locationIds: [],

            header: [
                {
                    label: '',
                    name: 'select',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'IDN/Provider Name',
                    name: 'idn',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Location Id',
                    name: 'location_id',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Location Name',
                    name: 'location_name',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Address',
                    name: 'address1',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                }
            ],
        }
    },
    mounted() {
        this.info = this.infoData
    },
    computed: {
        showSelectAllCoveredCheckbox() {
            return this.locationsCoveredList.some(item => !item.isNotified)
        },
        showSelectAllEmailedCheckbox() {
            return this.locationsEmailedList.some(item => !item.isNotified)
        },
        showSelectAllNonCoveredCheckbox() {
            return this.locationsNotCoveredList.some(item => !item.isNotified)
        },
    },
    methods: {
        addSuccess(file) {
            this.isFileSelect = false
            this.item = {
                location_id: null,
                file: null
            }

            if (file.name && _.isArray(file.name.split('.'))) {
                var ext = file.name.split('.')
                if (['xls','xlsx'].includes(ext[ext.length - 1])) {
                    this.item.file = file
                    try {
                        let formData = new FormData()
                        formData.append('file', this.item.file)
                        excelParser.getExcelHeaders(formData).then(res => {
                            if (!res.error) {
                                this.listHeader = res.data.d.map(x => { return { value: x, text: x } })
                                this.isFileSelect = true
                            }
                        })
                    } catch (e) {console.log(e) }
                } else {
                    this._showToast('Only accept xls, xlsx file format', { variant: 'danger' })
                }
            }
        },
        toggleSelectAll() {
            this.locationIds = []
            this.coveredLocationIds = []
            this.emailedLocationIds = []
            this.notCoveredLocationIds = []
            this.unRecognisedLocationIds = []
            this.Listitems = []
            if (this.selectAllCovered) {
                this.locationsCoveredList.forEach(item => {
                    if (!item.isNotified) {
                        this.coveredLocationIds.push(item.location_id)
                        this.Listitems.push(item)
                    }
                })
            }
            if (this.selectAllEmailed) {
                this.locationsEmailedList.forEach(item => {
                    if (!item.isNotified) {
                        this.emailedLocationIds.push(item.location_id)
                        this.Listitems.push(item)
                    }
                })
            }
            if (this.selectAllNonCovered) {
                this.locationsNotCoveredList.forEach(item => {
                    if (!item.isNotified) {
                        this.notCoveredLocationIds.push(item.location_id)
                        this.Listitems.push(item)
                    }
                })
            }
            if (this.selectAllUnrecognized) {
                this.locationsUnRecognisedList.forEach(item => {
                    this.unRecognisedLocationIds.push(item.location_id)
                    this.Listitems.push(item)
                })
            }
            this.locationIds = this.Listitems.map(item => item.location_id)
            if (this.Listitems.length > 0 && !this.isEnable) {
                this.isEnable = true
                this.$emit('updateIsEnable', this.isEnable)
            }
            if (this.Listitems.length === 0) {
                this.isEnable = false
                this.$emit('updateIsEnable', this.isEnable)
            }
        },
        checkboxChange(item, event) {
            if (event.target.checked) {
                this.Listitems.push(item)
            } else {
                this.Listitems = this.Listitems.filter(item1 => item1.location_id !== item.location_id)
            }
            const coveredNotifiedFalseLength = this.locationsCoveredList.filter(item => !item.isNotified).length
            const emailedNotifiedFalseLength = this.locationsEmailedList.filter(item => !item.isNotified).length
            const notCoveredNotifiedFalseLength = this.locationsNotCoveredList.filter(item => !item.isNotified).length

            this.locationIds = this.Listitems.map(item => item.location_id)
            this.selectAllCovered = (coveredNotifiedFalseLength === this.coveredLocationIds.length)
            this.selectAllEmailed = (emailedNotifiedFalseLength === this.emailedLocationIds.length)
            this.selectAllNonCovered = (notCoveredNotifiedFalseLength === this.notCoveredLocationIds.length)
            this.selectAllUnrecognized = (this.locationsUnRecognisedList.length === this.unRecognisedLocationIds.length)
            if (this.Listitems.length > 0 && !this.isEnable) {
                this.isEnable = true
                this.$emit('updateIsEnable', this.isEnable)
            }
            if (this.Listitems.length == 0) {
                this.isEnable = false
                this.$emit('updateIsEnable', this.isEnable)
            }
        },
        save() {
            this.$refs['observer'].validate().then(isValid => {
                if (isValid) {
                    let formData = new FormData()
                    formData.append('id', this.communicationId)
                    formData.append('locationIdHeader', this.item.location_id)
                    formData.append('file', this.item.file)
                    formData.append('communicationType', this.communicationType)
                    formData.append('isUploaded', this.isUploaded)
                    supplierRecallService.saveCommunicationStep3Locations(formData).then(res => {
                        if (!res.error) {
                            this.isFileSelect = false
                            this.item = null
                            this._showToast('Successfully uploaded')
                            this.showResult('fileupload',this.communicationId, res.data.d)
                        }
                    })
                }
            })
        },
        selectAll(){
            this.fetchAllLocationList()
        },
        fetchAllLocationList() {
            supplierRecallService.locationsBycommunicationId({
                ...this.coveredListSearchQuery,
                communicationId: this.communicationId
            }).then(resp => {
                this.showResult('selectAll',this.communicationId, 0)
            })
        },
        showResult(mode, communicationId, fileId) {
            this.locationModalKey += 1
            this.$nextTick(() => {
                this.$refs.locationModal.forceRerender(mode, communicationId, fileId)
            })
        },

        onCompleted() {
            this.fetchCoveredList()
            this.fetchNotCoveredList()
            this.fetchEmailedList()
            this.$parent.currentStep = 0
            this.$parent.currentStep = 3
        },

        fetchCoveredList() {
            supplierRecallService.findAffectedLocationsBycommunicationIdAndCoveredStatus({
                ...this.coveredListSearchQuery,
                sortField: this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].name : null,
                sortDirection: this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].order : null,
                communicationId: this.communicationId,
                type: 'COVERED'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsCoveredList = resp.data.d.c
                    this.totalLocationsCovered = resp.data.d.t
                }
            })
        },
        fetchEmailedList() {
            supplierRecallService.findAffectedLocationsBycommunicationIdAndCoveredStatus({
                ...this.coveredListSearchQuery,
                sortField: this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].name : null,
                sortDirection: this.coveredListSearchQuery.sort[0] ? this.coveredListSearchQuery.sort[0].order : null,
                communicationId: this.communicationId,
                type: 'EMAILED'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsEmailedList = resp.data.d.c
                    this.totalLocationsEmailed = resp.data.d.t
                }
            })
        },
        fetchNotCoveredList() {
            supplierRecallService.findAffectedLocationsBycommunicationIdAndCoveredStatus({
                ...this.notCoveredListSearchQuery,
                sortField: this.notCoveredListSearchQuery.sort[0] ? this.notCoveredListSearchQuery.sort[0].name : null,
                sortDirection: this.notCoveredListSearchQuery.sort[0] ? this.notCoveredListSearchQuery.sort[0].order : null,
                communicationId: this.communicationId,
                type: 'NON_COVERED'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsNotCoveredList = resp.data.d.c
                    this.totalLocationsNotCovered = resp.data.d.t
                }
            })
        },
        fetchUnRecognisedList() {
            supplierRecallService.findAffectedLocationsBycommunicationIdAndCoveredStatus({
                ...this.unRecognisedListSearchQuery,
                sortField: this.unRecognisedListSearchQuery.sort[0] ? this.unRecognisedListSearchQuery.sort[0].name : null,
                sortDirection: this.unRecognisedListSearchQuery.sort[0] ? this.unRecognisedListSearchQuery.sort[0].order : null,
                communicationId: this.communicationId,
                type: 'UNABLE_PROCESSED'
            }).then(resp => {
                if (!resp.error) {
                    this.locationsUnRecognisedList = resp.data.d.c
                    this.totalLocationsUnRecognised = resp.data.d.t
                }
            })
        },

        downloadFile(type) {
            supplierRecallService.downloadAffectedLocations({
                communicationId: this.communicationId,
                type
            }).then(resp => {
                if (!resp.error) {
                    window.open(resp.data.d, '_blank')
                }
            })
        },
        downloadAffectedLocationsXlxs(type) {
            supplierRecallService.getRecallById(this.communicationId).then(resp => {
                if (!resp.error) {
                    supplierRecallService.downloadAffectedLocationsXlxs({
                        communicationId: this.communicationId,
                        type
                    },resp.data.d.communicationUID).then(resp => {
                        if (!resp.error) {
                            window.open(resp.data.d, '_blank')
                        }
                    })
                }
            })

        },
        removeLocations() {
            supplierRecallService.removeAffectedLocations(this.communicationId, this.locationIds)
                .then(resp => {
                    if (!resp.error) {
                        if (this.locationIds.length === 1) {
                            this._showToast('Location removed successfully', { variant: 'success' })
                        } else {
                            this._showToast('Locations removed successfully', { variant: 'success' })
                        }
                        this.fetchCoveredList()
                        this.fetchNotCoveredList()
                        this.fetchEmailedList()
                        this.fetchUnRecognisedList()
                        this.isEnable = false
                        this.selectAllCovered = false
                        this.selectAllEmailed = false
                        this.selectAllNonCovered = false
                        this.selectAllUnrecognized = false
                        this.$emit('updateIsEnable', this.isEnable)
                        this.coveredLocationIds = [],
                        this.emailedLocationIds = [],
                        this.notCoveredLocationIds = [],
                        this.unRecognisedLocationIds = [],
                        this.locationIds = []
                    } else {
                        this._showToast(resp.errormessage || 'Failed to remove locations', { variant: 'danger' })
                    }
                })
                .catch(error => {
                    console.error('Error removing locations:', error)
                    this._showToast('An error occurred while removing locations', { variant: 'danger' })
                })
        }
    }
}